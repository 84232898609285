import React from 'react';
import BasicPage from "../components/BasicPage";

const ResetLinkExpired = (props) => {
  return (
    <>
      <BasicPage icon="icon-error" title="Password Reset" content="The reset link you clicked has expired." buttonText="Get a new link" buttonLink="/" />
    </>
  );
}

export default ResetLinkExpired