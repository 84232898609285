import React from 'react';
import { Link } from "react-router-dom";
import BasicLayout from "../components/BasicLayout";

const BasicPage = (props) => {
    let link = <Link to={props.buttonLink} className="button red without-icon">{props.buttonText}</Link>
    if(props.external){
        link = <a href={props.buttonLink} className="button red without-icon">{props.buttonText}</a>
    }
  return (
    <BasicLayout>
      <div className="text-center">
        <span className={`fs-50 ${props.icon}`}></span>
        <h2 className="mt-4">{props.title}</h2>
        <p className="mb-4" dangerouslySetInnerHTML={{ __html: props.content }} />
        {link}
      </div>
    </BasicLayout>
  );
}

export default BasicPage





