import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../BasicPage";
import { useTranslation } from 'react-i18next';

const AccountDeleted = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  
  return (
    <>
      <BasicPage icon="icon-error" title={t('Account deleted')} content={t('The account has been deleted.')} buttonText={t('Back to list')} buttonLink="/dealerboard" />
    </>
  );
}

export default AccountDeleted