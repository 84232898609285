import { combineReducers } from 'redux'
import userReducer from './User/user.reducer';
import fakeReducer from './Fakedata/fakedata.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  // changer ici le nom du reducer permettra de l'utiliser dans un affichage (voir Dealerboard.js par ex)
  fakedata: fakeReducer
});

export default rootReducer;