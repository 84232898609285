import React from 'react';
import {useHistory} from 'react-router-dom';
import BasicPage from "../components/BasicPage";
import { useTranslation } from 'react-i18next';

const ResetLinkSentCustomer = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  
  return (
    <>
      <BasicPage icon="icon-confirm-email" title={t('New reset link sent')} content={t(`An email forgot has been sent to {{email}} with a new link`,{email: history.location.state.email})} buttonText={t('Back to connexion page')} buttonLink="/customerboard" />
    </>
  );
}

export default ResetLinkSentCustomer