import React from 'react';
import BasicPage from "../components/BasicPage";
import { useTranslation } from 'react-i18next';

const PasswordResetConfirmation = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <BasicPage icon="icon-check" title={t('Password Reset')} content={t('Your password has been successfully reset.')} buttonText={t('Go to homepage ')} buttonLink="/" />
    </>
  );
}

export default PasswordResetConfirmation