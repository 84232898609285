import { REGISTER_USER_NAME, USER_IS_LOGGED } from './user.types';

const INITIAL_STATE = {
  name: "Unknown user",
  isLogged: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_USER_NAME:
      return {
        ...state, name: action.name,
      };
    case USER_IS_LOGGED:
     return {
      ...state, isLogged: true
     }
    default: return state;
  }
};

export default reducer;