import { LOAD_FAKE_DATA } from './fakedata.types';
import dataJson from '../../data/data.json'

const INITIAL_STATE = {
  data: dataJson
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_FAKE_DATA:
      return {
        ...state, data: action.data,
      };

    default: return state;
  }
};

export default reducer;